html,
body,
#root {
  height: 100%;
  background: black;
}

.App {
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  background: black;
  flex-direction: column;
  justify-content: space-evenly;
}

.rpv-core__inner-page {
  background-color: black !important;
}



.back-image {
  width: 80%;
  max-width: 500px;
}